import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "ml-5"
  }, [_c(VContainer, {
    staticClass: "py-4"
  }, [_c(VRow, {
    staticClass: "pa-0 pl-2 mt-0"
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "12",
      "xl": "10",
      "lg": "10"
    }
  }, [_c(VContainer, {
    ref: "scrollableChangelogs",
    staticClass: "overflow-y-auto",
    staticStyle: {
      "height": "70vh"
    }
  }, [_c('SiteHeaderComponent', {
    attrs: {
      "header": "Changelog"
    }
  }), _c('VueMarkdown', {
    attrs: {
      "source": _vm.markdownContent,
      "toc": "",
      "toc-anchor-class": "scrollactive-item"
    }
  })], 1)], 1), _c(VCol, {
    staticClass: "d-flex flex-column justify-start align-end",
    attrs: {
      "cols": "2"
    }
  }, [_c(VTimeline, _vm._l(_vm.tableOfContents, function (content) {
    return _c(VTimelineItem, {
      key: content.slug,
      staticClass: "mr-15 pa-1",
      class: {
        'primary--text font-weight-bold': content.hierarchy == 1,
        'pl-8': content.hierarchy == 2,
        'pl-16': content.hierarchy == 3
      },
      attrs: {
        "small": "",
        "right": "",
        "exact": "",
        "hide-dot": ""
      }
    }, [_c('div', {
      staticClass: "hoverCursor",
      on: {
        "click": function ($event) {
          return _vm.scrollTo(content);
        }
      }
    }, [_c('span', [_vm._v(" " + _vm._s(content.title) + " ")])])]);
  }), 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };