import VueMarkdown from 'vue-markdown';
import SiteHeaderComponent from '../../components/core/SiteHeaderComponent.vue';
export default {
  components: {
    VueMarkdown,
    SiteHeaderComponent
  },
  data: () => ({
    allVersions: ['2.0.0', '1.0.0'],
    markdownContent: ''
  }),
  computed: {
    tableOfContents() {
      var tableOfContents = [];
      const headings = this.markdownContent.match(/^(#+) (.+)$/gm);
      if (headings) {
        tableOfContents = headings.map(heading => {
          const hierarchy = heading.match(/#/g).length;
          const title = heading.replace(/^(#+) /, '');
          const sanitizedTitle = title.toLowerCase().replace(/[^\w\säöüß-]/g, '') // Hier wurden ä, ö, ü, ß hinzugefügt
          .replace(/\s+/g, '-');
          const id = sanitizedTitle.replace(/^[^a-zA-Z]+/, 'a');
          const active = false;
          return {
            title,
            hierarchy,
            id,
            active
          };
        });
      }
      return tableOfContents;
    }
  },
  methods: {
    async getChangelog() {
      var changelog = '';
      for (var version of this.allVersions) {
        var fileName = 'v' + version.replace(/\./g, '-') + '.md';
        changelog += (await import(`@/docs/release/${fileName}`)).default + '\n';
      }
      return changelog;
    },
    scrollTo(content) {
      const scrollableChangelogs = this.$refs.scrollableChangelogs;
      const section = document.getElementById(content.id);
      if (scrollableChangelogs && section) {
        const scrollableChangelogsRect = scrollableChangelogs.getBoundingClientRect();
        const sectionRect = section.getBoundingClientRect();
        const scrollY = sectionRect.top - scrollableChangelogsRect.top + scrollableChangelogs.scrollTop;
        scrollableChangelogs.scrollTo({
          top: scrollY,
          behavior: 'smooth'
        });
      }
    }
  },
  watch: {},
  async mounted() {
    this.markdownContent = await this.getChangelog();
  }
};